import React, { Component } from "react";
import { Link } from "gatsby";
import Header from "./header"
import ReactGA from 'react-ga'
import _ from 'lodash'

import LocationIcon from '../../images/locationicon.svg'
import bannerImg from '../../images/bannerimg.png'
import dumpsterImgOne from '../../images/dumpsterimgone.svg'
import dumpsterImgTwo from '../../images/dumpsterimgtwo.svg'
import arrowImg from '../../images/arrowicon.svg'
import phoneIcon from '../../images/phoneicon.svg'
import scrollDown from '../../images/scrolldown.svg'
import WorkOne from '../../images/work1.svg'
import WorkTwo from '../../images/work2.svg'
import WorkThree from '../../images/work3.svg'
import videoBg from '../../images/videobg.png'
import authorName from '../../images/authorname.svg'
import HeroPromotion from "../../images/promotion.mp4"
// import HeroPoster from "../../images/video-thumb.png"
import HeroPoster from "../../images/video-thumb.jpg"
import VideoTop from "../../images/videotop.svg"
import HeroPromotion2 from "../../images/promotion2.mp4"
// import HeroPoster2 from "../../images/video-thumb2.png"
import HeroPoster2 from "../../images/video-thumb2.jpg"

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "The easiest and most affordable debris removal service in New York | Curbside"
    console.log(this.props.location, '-----------')
    const gclid = _.get(this.props.location, 'search', '').replace('?gclid=', '')
    if(gclid && gclid !== '') {
      localStorage.setItem('gclid', gclid)
    } else {
      localStorage.removeItem('gclid')
    }
  }
  handleEventCall() {
    if(process.env.REACT_APP_GA) {
      ReactGA.pageview(ReactGA.pageview(_.get(this.props, 'location.pathname', '/')))
      ReactGA.event({
        category: 'Clicks to the phone number',
        action: 'Clicks to the phone number'
      })
    }
  }
  render() {
    return (
      <div>
        <div className="hero-section">
          <Header />
            <div className="top__small-header">
            <img src={LocationIcon} alt="" /> Servicing New York
            </div>
            <div className="clearfix"></div>
            <div className="banner__bg">
            <div className="dark-overlay"></div>
            <div className="hero-video-wrapper">
              <video poster={HeroPoster} playsInline autoPlay muted loop>
                {/* <source src={HeroPromotion} type="video/webm" /> */}
                <source src={HeroPromotion} type="video/mp4" />
              </video>
            </div>
              <div className="center__box">
                <h2>Debris disposal without the mess</h2>
                <p>The easiest and most affordable debris removal service in New York</p>
                 {/*  <Link
                  to={`/curbside-now/new-york`}
                  state={{ tab: "dumpster" }}
                >
                  <div className="box">
                    <div className="yellow__box"><img src={dumpsterImgOne} alt="" /></div>
                    <div className="text__box">
                    <h4>I need a dumpster</h4>
                      <p>We will deliver the dumpster where you need it. When it is full and ready, we will pick it up!</p>
                    </div>
                    <div className="arrow__box"><img src={arrowImg} alt="" /></div>
                  </div>
                </Link>
                <Link
                  to={`/curbside-now/new-york`}
                  state={{ tab: "Live Load" }}
                >
                  <div className="box">
                    <div className="yellow__box"><img src={dumpsterImgTwo} alt="" /></div>
                    <div className="text__box">
                    <h4>I need a debris truck</h4>
                      <p>We will send our debris truck to pick up whatever you need upon arrival.</p>
                    </div>
                    <div className="arrow__box"><img src={arrowImg} alt="" /></div>
                  </div>
                </Link>
              <div className="phone">
                <h5>Or speak to an expert</h5>
                <span><img src={phoneIcon} alt="" /> <span onClick={this.handleEventCall.bind(this)}><a href="tel:7183846357">718-384-6357 </a></span></span>
              </div> */}
              </div>

              <span className="hero-arrow">
                <img src={scrollDown} alt="" className="bounce" />
              </span>
            </div>
          <div className="clearfix"></div>

            {/* <div className="smallview__box">
              <Link
                to={`/curbside-now`}
                state={{ tab: "dumpster" }}
              >
                <div className="box">
                  <div className="yellow__box"><img src={dumpsterImgOne} alt="" /></div>
                  <div className="text__box">
                  <h4>I need a dumpster</h4>
                    <p>We will deliver the dumpster where you need it. When it is full and ready, we will pick it up!</p>
                  </div>
                  <div className="arrow__box"><img src={arrowImg} alt="" /></div>
                </div>
              </Link>
              <Link
                to={`/curbside-now`}
                state={{ tab: "Live Load" }}
              >
                <div className="box">
                  <div className="yellow__box"><img src={dumpsterImgTwo} alt="" /></div>
                  <div className="text__box">
                  <h4>I need a Debris truck</h4>
                    <p>We will send our debris truck to pick up whatever you need upon arrival.</p>
                  </div>
                  <div className="arrow__box"><img src={arrowImg} alt="" /></div>
                </div>
              </Link>
            </div> */}
          <div className="clearfix"></div>
         </div>


        <section className="graybg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>How Curbside Works</h2>
                <p>Get started in three easy steps. We have the technology to keep things as efficient and sustainable as possible to make your project run smoothly.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul>
                  <li>
                    <img src={WorkOne} alt="" />
                    <h5>Select & Schedule Service</h5>
                    <p>Select the day and time for your debris removal and get instant, affordable pricing. </p>
                  </li>
                  <li>
                    <img src={WorkTwo} alt="" />
                    <h5>Manage Everything Online</h5>
                    <p>Ordering, contacting the Curbside team, and monitoring your orders are just a click away.</p>
                  </li>
                  <li>
                    <img src={WorkThree} alt="" />
                    <h5>Sit Back and Relax</h5>
                    <p>The Curbside driver will execute your services safely and on time.</p>
                  </li>
                </ul>
                <Link
                  to={`/curbside-now`}
                >
                  <button className="btn btn-darkbtn yellowbtn">Order Now</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix"></div>

        <section className="video__section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="leftsec">
                  <h3>Covering all you covered in all areas of your life </h3>
                  <p>You can use Curbside for all of your debris disposal needs. Here are some of the most popular services:</p>
                  <ul>
                    <li><a href="/curbside-now">Junk Removal </a></li>
                    <li><a href="/curbside-now">Recycling</a></li>
                    <li><a href="/curbside-now">Estate Clean out</a></li>
                    <li><a href="/curbside-now">Hoarding</a></li>
                    <li><a href="/curbside-now">Garage Cleaning</a></li>
                    <li><a href="/curbside-now">Cleanup</a></li>
                    <li><a href="/curbside-now">Seasonal</a></li>
                    <li><a href="/curbside-now">Commercial</a></li>
                    <li><a href="/curbside-now">Removal</a></li>
                    <li><a href="/curbside-now">Junk Removal</a></li>
                    <li><a href="/curbside-now">Storm Recovery</a></li>
                    <li><a href="/curbside-now">Construction Debris</a></li>
                    <li><a href="/curbside-now">Demolition</a></li>
                  </ul>
                </div>
                <div className="rightsec">
                  <div className="hero-video-wrapper2">
                    <video poster={HeroPoster2} playsInline autoPlay muted loop >
                      {/* <source src={HeroPromotion2} type="video/webm" /> */}
                      <source src={HeroPromotion2} type="video/mp4" />
                    </video>
                  </div>

            {/* <img src={videoBg} alt="" /> */}
            </div>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix"></div>

        <section>
          <div className="container blackbox">
            <div className="row">
              <div className="col-md-12">
                <h6>A WORD FROM US</h6>
                <p>Curbside was founded with the mission to bring new ideas to the debris removal space. Our goal each and every day is to work with our clients to provide quality, sustainable and cost effective service while investing in a more tech enhanced future. We have built a dedicated and diverse team who will provide the highest level of customer service. We will continue to provide the newest and most forward thinking experience so our clients can focus on the job at hand. We look forward to working with you.</p>
                <h1>John Alfieri</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix"></div>








        {/* <section className="section-testimonials">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="testimonials-wrapper">
                  <h2>a word from us</h2>
                  <p>
                    Curbside was founded with the mission to bring new ideas to the debris removal space. Our goal each and every day is to work with our clients to provide quality, sustainable and cost effective service while investing in a more tech enhanced future. We have built a dedicated and diverse team who will provide the highest level of customer service. We will continue to provide the newest and most forward thinking experience so our clients can focus on the job at hand. We look forward to working with you.
                  </p>
                  <h4 className="signature-text">Michael Marmo </h4>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export default Homepage;
