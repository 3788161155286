import React from "react"
import Layout from "../components/layout"
import Index from "../components/marketing/homepage"
import SEO from "../components/seo"

const IndexPage = (props) => (
  <Layout>
    <SEO title="Home" />
    <Index {...props}/>
  </Layout>
)

export default IndexPage
